import './bootstrap'
import '../css/app.css'
import { createApp, DefineComponent, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import * as Sentry from '@sentry/vue'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel'

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),

  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue)
    // Initialize Sentry
    Sentry.init({
      app: app, // pass the Vue app instance here
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
        }),
      ],
      tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
      profilesSampleRate: import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE,

      // Session Replay
      replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })

    app.mount(el)

    return app
  },
  progress: {
    color: '#29d',
  },
})
